<template>
<div class="flex w-full vx-row no-gutter flex-wrap items-end justify-center login-main" id="page-login">
    <span class="top-bg-overly"><img src="../../assets/images/login-left.png" /></span>
    <div class="container">
        <div class="vx-row justify-center">
            <div class="vx-col md:w-3/4 w-full">
                <div class="vx-card__title login-title">
                    <h2>
                        <span>Welcome to</span> <br />
                        Visual Visitor.
                    </h2>
                    <p>Welcome back, please login to your account</p>
                </div>
            </div>
        </div>
        <div class="vx-row justify-center">
            <div class="vx-col login-form-info md:w-3/4 w-full">
                <vx-card>
                    <div class="vx-row justify-center items-center">
                        <!-- Login Image -->
                        <div class="vx-col hidden lg:block lg:w-7/12 order-2">
                            <img src="@/assets/images/login-right.svg" alt="login" class="mx-auto login-img" />
                        </div>
                        <!-- Login Form -->
                        <div class="vx-col w-full lg:w-5/12 d-theme-dark-bg order-1">
                            <div class="p-4 login-tabs-container">
                                <div class="vx-card__title mb-2">
                                    <h4>Login</h4>
                                    <span class="text-danger text-sm">{{
                      loginErrorMessage
                    }}</span>
                                </div>
                                <div>
                                    <form data-vv-scope="loginForm">
                                        <div>
                                            <vs-input name="email" icon-no-border icon="icon icon-user" icon-pack="feather" label-placeholder="Email" v-validate="'required|email'" v-model="email" class="w-full" />
                                            <span class="text-danger text-sm">{{
                          errors.first("loginForm.email")
                        }}</span>
                                        </div>
                                        <div>
                                            <vs-input type="password" name="password" icon-no-border icon="icon icon-lock" icon-pack="feather" label-placeholder="Password" v-validate="'required'" v-model="password" class="w-full" @keyup.enter="login()" />
                                            <span class="text-danger text-sm">{{
                          errors.first("loginForm.password")
                        }}</span>
                                        </div>
                                        <div class="flex float-left flex-wrap justify-between mt-5">
                                            <vs-checkbox v-model="rememberMyEmail" class="mb-3">Remember my email address?</vs-checkbox>
                                        </div>
                                    </form>
                                    <vs-button color="secondary" id="user-login" :disabled="button_loading" type="filled" class="w-full float-left mt-6" @click="login()">Login</vs-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </vx-card>
                <router-link :to="{name: 'page-Forgotpassword'}" class="forgot-pass">Forgot Password? <feather-icon icon="ArrowRightIcon"></feather-icon>
                </router-link>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Cookie from "js-cookie";
import CryptoJS from "crypto-js"
import constantKey from '../../constants/constant-key.json';
import store from "../../store/store";
import {
    Validator
} from "vee-validate";
const dict = {
    custom: {
        email: {
            required: "Please enter email",
            email: "Please enter valid email"
        },
        password: {
            required: "Please enter password"
        }
    }
};
Validator.localize("en", dict);
export default {
    data() {
        return {
            email: "",
            password: "",
            loginErrorMessage: null,
            rememberMyEmail: false,
            interval: null,
            challengeToken: null
        };
    },
    created() {
        this.interval = setInterval(() => this.checkAppVersion(), 1000 * 300 * 1)
        localStorage.removeItem("VVUserInfo");
    },
    mounted() {
        this.getRememberMeEmail();
        this.getRememberMyDevice();
        this.setAppVersion()
    },
    methods: {
        getRememberMeEmail() {
            if (Cookie.get("VVRememberMyEmail")) {
                this.email = Cookie.get("VVRememberMyEmail");
                this.rememberMyEmail = true;
            }
        },
        getRememberMyDevice(){
            if(Cookie.get("VVRememberMyDevice")){
                let decryptUserInfo = CryptoJS.AES.decrypt(Cookie.get("VVRememberMyDevice"), constantKey.VV_SECRET_KEY);
                let plainUserInfo = JSON.parse(decryptUserInfo.toString(CryptoJS.enc.Utf8));
                this.challengeToken = plainUserInfo;
            }
        },

        login() {
            this.$validator.validateAll("loginForm").then(result => {
                if (result) {
                    this.button_loader(true, "#user-login");

                    let url = "Account/GenerateTokenV3Json";
                    this.loginErrorMessage = null;
                    let loginParams = {
                        Email: this.email,
                        Password: this.password,
                        CT:this.challengeToken ? this.challengeToken : ""
                    }
                    this.axios
                        .post(url, loginParams)
                        .then(response => {


                            //Set cookie for remember my email
                            if (this.rememberMyEmail) {
                                Cookie.set("VVRememberMyEmail", this.email, {
                                    expires: 180
                                });
                            } else {
                                Cookie.remove("VVRememberMyEmail");
                            }

                            //Set cookie for token gnerated time
                            let tokenGeneratedAt = new Date();
                            tokenGeneratedAt.setHours(tokenGeneratedAt.getHours() + 1);
                            Cookie.set("VVtokenGeneratedAt", tokenGeneratedAt);

                            let data = response.data;
                            this.button_loader(false, "#user-login");
                            let token = [];
                            token.push({
                                id: "primary",
                                token: data.token,
                                refreshToken: data.refreshToken,
                                vendastaIsPartner: false,
                                vendastaAccountId: '',
                                vendastaProductId: '',
                                vendastaThirdpartyNavbarUrl: '',
                                isLogedIn: true,
                                menuItems: null,
                                themeDetail: null,
                                isReseller: null,
                                isActiveFreeTrial: null,
                                isExpiredFreeTrial: null,
                                resellerIsLegacyOnly: null,
                                ShowContactReport: null,
                                userDetails: null,
                                maxLocalNumbers: null,
                            });
                            localStorage.setItem("VVtoken", JSON.stringify(token));
                            this.$store.dispatch("updateAuthToken", token);

                            this.$gtag.event('login', { method: 'Google' })
                            let url = "Account/GetInterfaceOptions";
                            this.axios.defaults.headers.common["Authorization"] =
                                "Bearer " + data.token;
                            this.axios
                                .get(url)
                                .then(response => {
                                    let data = response.data;
                                    let menuItem = data.MenuItems;
                                    let themeDetail = data.DashboardThemeV2;
                                    themeDetail.tempActionButtonColor =
                                        this.RGBToHex(data.DashboardThemeV2.ActionButtonColor);
                                    themeDetail.tempChartColor1 =
                                        this.RGBToHex(data.DashboardThemeV2.ChartColor1);
                                    themeDetail.tempChartColor2 =
                                        this.RGBToHex(data.DashboardThemeV2.ChartColor2);
                                    themeDetail.tempChartColor3 =
                                        this.RGBToHex(data.DashboardThemeV2.ChartColor3);
                                    themeDetail.tempChartColor4 =
                                        this.RGBToHex(data.DashboardThemeV2.ChartColor4);
                                    themeDetail.tempChartColor5 =
                                        this.RGBToHex(data.DashboardThemeV2.ChartColor5);
                                    themeDetail.tempChartColor6 =
                                        this.RGBToHex(data.DashboardThemeV2.ChartColor6);
                                    themeDetail.tempChartColor7 =
                                        this.RGBToHex(data.DashboardThemeV2.ChartColor7);
                                    themeDetail.tempChartColor8 =
                                        this.RGBToHex(data.DashboardThemeV2.ChartColor8);
                                    themeDetail.tempFirstColor =
                                        this.RGBToHex(data.DashboardThemeV2.FirstColor);
                                    themeDetail.tempSecondColor =
                                        this.RGBToHex(data.DashboardThemeV2.SecondColor);
                                    themeDetail.tempThirdColor =
                                        this.RGBToHex(data.DashboardThemeV2.ThirdColor);
                                    themeDetail.tempFourthColor =
                                        this.RGBToHex(data.DashboardThemeV2.FourthColor);
                                    themeDetail.tempNavbarColor =
                                        this.RGBToHex(data.DashboardThemeV2.NavbarColor);
                                    themeDetail.tempThemeColor =
                                        this.RGBToHex(data.DashboardThemeV2.ThemeColor);
                                    let isReseller = data.Reseller;
                                    let isActiveFreeTrial = data.IsActiveFreeTrial;
                                    let isExpiredFreeTrial = data.FreeTrialIsExpired;
                                    let resellerIsLegacyOnly = data.Reseller_Is_Legacy_Only;
                                    let ShowContactReport = data.ShowContactReport;
                                    let userDetails = data.UserInformation;
                                    let maxLocalNumbers = data.MaxLocalNumbers;
                                    let DefaultHomePage = data.DefaultHomePage;
                                    let showBillingV2 = data.ShowBillingV2;
                                    let showGuidedTour = data.ShowGuidedTour;
                                    let isConsumerPlan = data.Is_Consumer_Plan;
                                    let isReportSettingsReadOnly = data.Is_Report_Settings_ReadOnly;
                                    let screenItemsVisiblity = data.ScreenItems;
                                    let enableZapierIntegration = data.EnableZapierIntegration;
                                    let isResellerPrimaryAccount = data.Is_Reseller_Primary_Account;
                                    let isResellerSubaccount = data.Is_Reseller_Subaccount;

                                    let VVtoken = localStorage.getItem("VVtoken");
                                    let token = JSON.parse(VVtoken);
                                    token[0].menuItems = menuItem;
                                    token[0].themeDetail = themeDetail;
                                    token[0].isReseller = isReseller;
                                    token[0].isActiveFreeTrial = isActiveFreeTrial;
                                    token[0].isExpiredFreeTrial = isExpiredFreeTrial;
                                    token[0].resellerIsLegacyOnly = resellerIsLegacyOnly;
                                    token[0].ShowContactReport = ShowContactReport;
                                    token[0].userDetails = userDetails;
                                    token[0].maxLocalNumbers = maxLocalNumbers;
                                    token[0].DefaultHomePage = DefaultHomePage;
                                    token[0].showBillingV2 = showBillingV2;
                                    token[0].showGuidedTour = showGuidedTour;
                                    token[0].screenItems = screenItemsVisiblity;
                                    token[0].isConsumerPlan = isConsumerPlan;
                                    token[0].isReportSettingsReadOnly = isReportSettingsReadOnly;
                                    token[0].enableZapierIntegration = enableZapierIntegration;
                                    token[0].isResellerPrimaryAccount = isResellerPrimaryAccount;
                                    token[0].isResellerSubaccount = isResellerSubaccount;

                                    localStorage.setItem("VVtoken", JSON.stringify(token));
                                    store.dispatch("updateAuthToken", token);
                                    clearInterval(this.interval);

                                    let redirectDefaultUrl = this.getRedirectDefaultUrl(data.DefaultHomePage)
                                    this.$router.push(redirectDefaultUrl + 0);

                                     })
                                    .catch(() => {
                                        // this.showError(e);
                                        this.logout();
                                    });
                        })
                        .catch(e => {
                            if (e.response.status == 401) {
                              console.log(e.response.data);


                                    let userInfo = [{
                                        userEmail: this.email,
                                        userPass: this.password
                                    }]

                                    let encryptUserInfo = CryptoJS.AES.encrypt(JSON.stringify(userInfo), constantKey.VV_SECRET_KEY).toString();
                                    localStorage.setItem("VVUserInfo", encryptUserInfo);

                                this.$router.push({
                                    name: 'page-two-factor-auth'
                                });
                            } else {
                                this.loginErrorMessage = e.response.data.Errors[0].Message;
                                this.showError(e, "#user-login");
                            }
                        });
                }
            });
        },
        setAppVersion() {
            this.axios.get('/Account/GetCurrentVersion').then(response => {
                let data = response.data;
                this.$store.commit('APP_VERSION', data)
            }).catch(e => {
                this.showError(e);
            });
        },
        checkAppVersion() {
            this.axios.get('/Account/GetCurrentVersion').then(response => {
                //
                let data = response.data;
                if (data != this.$store.state.appVersion) {
                    window.location.reload();
                }
                // else{
                //
                // }
            }).catch(e => {
                this.showError(e);
            });
        },
    }
};
</script>

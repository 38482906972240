<template>
  <div>
    <!-- <p>Loading...</p>
    <div id="loading-bg">
      <div class="loading mt-0">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div> -->
    <!-- <login-default></login-default> -->
    <login-default v-if="isDefaultLogin == true && !accountId"></login-default>

    <login-reseller
      v-if="isDefaultLogin == false && !accountId"
    ></login-reseller>
    <login-customer v-if="isCustomer"></login-customer>
  </div>
</template>

<script>
import LoginDefault from "../../components/login/LoginDefault";
import LoginReseller from "../../components/login/LoginReseller";
import LoginCustomer from "../../components/login/LoginCustomer.vue";
import Cookie from "js-cookie";
import store from "../../store/store";

export default {
  title: "Login",
  components: {
    LoginDefault,
    LoginReseller,
    LoginCustomer,
  },
  data() {
    return {
      isDefaultLogin: null,
      vendastaIsPartner: null,
      accountId: null,
      state: null,
      code: null,
      scope: null,
      clientID: null,
      isCustomer: null
    };
  },
  created() {
    if(location.host.includes('visitormatch.com')){
      if (this.$route.params.reload) {
        location.reload();
      }
      this.isCustomer = true
      var link = document.querySelector("link[rel*='icon']") || document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = "/favicon/favicon-generic.png";
      document.getElementsByTagName("head")[0].appendChild(link);
    }else{
      if (this.$route.params.reload) {
        location.reload();
      } else if (
        (this.$route.query.accountid ||
          localStorage.getItem("VendastaVVAccountId")) &&
        !this.$route.query.state &&
        !this.$route.query.code &&
        !this.$route.query.scope
      ) {
        this.accountId = this.$route.query.accountid
          ? this.$route.query.accountid
          : JSON.parse(localStorage.getItem("VendastaVVAccountId"));
        localStorage.setItem(
          "VendastaVVAccountId",
          JSON.stringify(this.accountId)
        );

        this.getVendastaClientId();
      } else if (
        this.$route.query.state &&
        this.$route.query.code &&
        this.$route.query.scope
      ) {
        this.state = this.$route.query.state;
        this.code = this.$route.query.code;
        this.scope = this.$route.query.scope;
        let VendastaVVAccountId = localStorage.getItem("VendastaVVAccountId");
        this.accountId = JSON.parse(VendastaVVAccountId);
        this.getFullHostName();
      } else {
        this.getFullHostName();
      }
      if (this.$route.query.msg) {
        this.$vs.notify({
          title: "Success",
          text: this.$route.query.msg,
          color: "success",
          iconPack: "feather",
          icon: "icon-check",
          position: "top-right",
          time: 4000,
        });
      }
    }
  },
  methods: {
    getVendastaClientId() {
      this.$vs.loading();
      let url = "Account/GetLoginPageInfoV2";
      url += "?id=" + location.host;
      // url += '?id=visualvisitor.zignuts.com';
      this.axios
        .get(url)
        .then((response) => {
          let data = response.data;
          this.clientID = data.ClientId;
          window.location = encodeURI(
            "https://sso-api-prod.apigateway.co/oauth2/auth?account_id=" +
              this.accountId +
              "&client_id=" +
              this.clientID +
              "&redirect_uri=https://" +
              location.host +
              "/pages/login&response_type=code&state=12345&prompt=login&scope=openid profile"
          );
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e, "#user-login");
        });
    },
    getFullHostName() {
      this.$vs.loading();
      let url = "Account/GetLoginPageInfoV2";
      url += "?id=" + location.host;
      // url += '?id=visualvisitor.zignuts.com';
      this.axios
        .get(url)
        .then((response) => {
          let data = response.data;
          this.isDefaultLogin = data.Default_Login_Page;
          this.vendastaIsPartner = data.Is_Partner;
          if (
            this.vendastaIsPartner &&
            this.accountId &&
            !this.isDefaultLogin
          ) {
            var link =
              document.querySelector("link[rel*='icon']") ||
              document.createElement("link");
            link.type = "image/x-icon";
            link.rel = "shortcut icon";
            link.href = "/favicon/favicon-generic.png";
            document.getElementsByTagName("head")[0].appendChild(link);
            this.vendastaLogin(
              this.code,
              this.scope,
              this.state,
              data.PartnerProductId,
              this.accountId
            );
          } else if (
            !this.vendastaIsPartner &&
            !this.accountId &&
            !this.isDefaultLogin
          ) {
            var link2 =
              document.querySelector("link[rel*='icon']") ||
              document.createElement("link");
            link2.type = "image/x-icon";
            link2.rel = "shortcut icon";
            link2.href = "/favicon/favicon-generic.png";
            document.getElementsByTagName("head")[0].appendChild(link);
          } else if (
            !this.vendastaIsPartner &&
            !this.accountId &&
            this.isDefaultLogin
          ) {
            var link3 =
              document.querySelector("link[rel*='icon']") ||
              document.createElement("link");
            link3.type = "image/x-icon";
            link3.rel = "shortcut icon";
            link3.href = "/favicon/favicon.icon";
            document.getElementsByTagName("head")[0].appendChild(link3);
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e, "#user-login");
        });
    },
    vendastaLogin(code, scope, state, productId, accountId) {
      this.$vs.loading();
      let vendastaLoginParams = {
        Code: code,
        Scope: scope,
        State: state,
        ProductId: productId,
        AccountID: accountId,
      };
      this.axios
        .post(
          "/ws/ExternalPartnerIntegration/GeneratePartnerSession",
          vendastaLoginParams
        )
        .then((response) => {
          // console.log(data)
          //Set cookie for token gnerated time
          let tokenGeneratedAt = new Date();
          tokenGeneratedAt.setHours(tokenGeneratedAt.getHours() + 1);
          Cookie.set("VVtokenGeneratedAt", tokenGeneratedAt);

          let data = response.data;
          let token = [];
          token.push({
            id: "primary",
            token: data.token,
            refreshToken: data.refreshToken,
            vendastaIsPartner: this.vendastaIsPartner,
            vendastaAccountId: accountId,
            vendastaProductId: productId,
            vendastaThirdpartyNavbarUrl: null,
            isLogedIn: true,
            menuItems: null,
            themeDetail: null,
            isReseller: null,
            isActiveFreeTrial: null,
            isExpiredFreeTrial: null,
            resellerIsLegacyOnly: null,
            ShowContactReport: null,
            userDetails: null,
            maxLocalNumbers: null,
          });
          localStorage.setItem("VVtoken", JSON.stringify(token));
          this.$store.dispatch("updateAuthToken", token);

          this.$gtag.event("login", { method: "Google" });
          let url = "Account/GetInterfaceOptions";
          this.axios.defaults.headers.common["Authorization"] =
            "Bearer " + data.token;
          this.axios
            .get(url)
            .then((response) => {
              let data = response.data;
              let menuItem = data.MenuItems;
              let themeDetail = data.DashboardThemeV2;
              let thirdpartyNavbarUrl = data.Thirdparty_Navbar_Url;
              themeDetail.tempActionButtonColor = this.RGBToHex(
                data.DashboardThemeV2.ActionButtonColor
              );
              themeDetail.tempChartColor1 = this.RGBToHex(
                data.DashboardThemeV2.ChartColor1
              );
              themeDetail.tempChartColor2 = this.RGBToHex(
                data.DashboardThemeV2.ChartColor2
              );
              themeDetail.tempChartColor3 = this.RGBToHex(
                data.DashboardThemeV2.ChartColor3
              );
              themeDetail.tempChartColor4 = this.RGBToHex(
                data.DashboardThemeV2.ChartColor4
              );
              themeDetail.tempChartColor5 = this.RGBToHex(
                data.DashboardThemeV2.ChartColor5
              );
              themeDetail.tempChartColor6 = this.RGBToHex(
                data.DashboardThemeV2.ChartColor6
              );
              themeDetail.tempChartColor7 = this.RGBToHex(
                data.DashboardThemeV2.ChartColor7
              );
              themeDetail.tempChartColor8 = this.RGBToHex(
                data.DashboardThemeV2.ChartColor8
              );
              themeDetail.tempFirstColor = this.RGBToHex(
                data.DashboardThemeV2.FirstColor
              );
              themeDetail.tempSecondColor = this.RGBToHex(
                data.DashboardThemeV2.SecondColor
              );
              themeDetail.tempThirdColor = this.RGBToHex(
                data.DashboardThemeV2.ThirdColor
              );
              themeDetail.tempFourthColor = this.RGBToHex(
                data.DashboardThemeV2.FourthColor
              );
              themeDetail.tempNavbarColor = this.RGBToHex(
                data.DashboardThemeV2.NavbarColor
              );
              themeDetail.tempThemeColor = this.RGBToHex(
                data.DashboardThemeV2.ThemeColor
              );
              let isReseller = data.Reseller;
              let isActiveFreeTrial = data.IsActiveFreeTrial;
              let isExpiredFreeTrial = data.FreeTrialIsExpired;
              let resellerIsLegacyOnly = data.Reseller_Is_Legacy_Only;
              let ShowContactReport = data.ShowContactReport;
              let userDetails = data.UserInformation;
              let maxLocalNumbers = data.MaxLocalNumbers;
              let DefaultHomePage = data.DefaultHomePage;
              let showBillingV2 = data.ShowBillingV2;
              let showGuidedTour = data.ShowGuidedTour;
              let isConsumerPlan = data.Is_Consumer_Plan;
              let isReportSettingsReadOnly = data.Is_Report_Settings_ReadOnly;
              let screenItemsVisiblity = data.ScreenItems;
              let enableZapierIntegration = data.EnableZapierIntegration;
              let isResellerPrimaryAccount = data.Is_Reseller_Primary_Account;
              let isResellerSubaccount = data.Is_Reseller_Subaccount;

              let VVtoken = localStorage.getItem("VVtoken");
              let token = JSON.parse(VVtoken);
              token[0].menuItems = menuItem;
              token[0].vendastaThirdpartyNavbarUrl = thirdpartyNavbarUrl;
              token[0].themeDetail = themeDetail;
              token[0].isReseller = isReseller;
              token[0].isActiveFreeTrial = isActiveFreeTrial;
              token[0].isExpiredFreeTrial = isExpiredFreeTrial;
              token[0].resellerIsLegacyOnly = resellerIsLegacyOnly;
              token[0].ShowContactReport = ShowContactReport;
              token[0].userDetails = userDetails;
              token[0].maxLocalNumbers = maxLocalNumbers;
              token[0].DefaultHomePage = DefaultHomePage;
              token[0].showBillingV2 = showBillingV2;
              token[0].showGuidedTour = showGuidedTour;
              token[0].screenItems = screenItemsVisiblity;
              token[0].isConsumerPlan = isConsumerPlan;
              token[0].isReportSettingsReadOnly = isReportSettingsReadOnly;
              token[0].enableZapierIntegration = enableZapierIntegration;
              token[0].isResellerPrimaryAccount = isResellerPrimaryAccount;
              token[0].isResellerSubaccount = isResellerSubaccount;

              localStorage.setItem("VVtoken", JSON.stringify(token));
              store.dispatch("updateAuthToken", token);

              clearInterval(this.interval);

              localStorage.removeItem("VendastaVVAccountId");

              this.$router.push(
                "/consumer/dashboard/uid/0/" + token[0].vendastaAccountId
              );
              this.$vs.loading.close();
            })
            .catch(() => {
              // this.showError(e);
              this.logout();
            });
        })
        .catch(() => {
          this.$vs.loading.close();
          // console.log('error occured while API called!!')
        });
    },
  },
};
</script>

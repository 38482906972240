<template>
  <div class="customer_sec">
    <div class="flex">
      <div class="left_section display"></div>

      <div class="customer_signup">
        <div class="right_sec remove_center_align">
          <div class="form_head">
            <img
              src="../../assets/images/visitormatch-logo.png"
              alt="logo"
              width="145"
            />
            <h1 class="sign_header">Sign into your account</h1>
            <div class="c_header_Sec">
              <form data-vv-scope="loginForm" @submit.prevent>
                <ul class="input_listing">
                  <li class="login_li">
                    <div class="input_sec">
                      <input
                        :class="
                          isErrorExist(errors, 'email')
                            ? 'error-customer-field'
                            : ''
                        "
                        type="text"
                        placeholder="Your email address"
                        class="w-full"
                        v-model="email"
                        name="email"
                        v-validate="'required|email'"
                      />
                      <b-icon
                        v-show="isErrorExist(errors, 'email')"
                        icon="exclamation-circle-fill"
                        font-scale="1.5"
                        class="text-white"
                      ></b-icon>
                    </div>
                    <span class="error_msg mb-0">{{
                      errors.first("loginForm.email")
                    }}</span>
                  </li>
                  <li>
                    <div class="input_sec">
                      <input
                        :class="
                          isErrorExist(errors, 'password')
                            ? 'error-customer-field'
                            : ''
                        "
                        type="password"
                        placeholder="The password you picked"
                        class="w-full"
                        v-model="password"
                        name="password"
                        v-validate="'required'"
                        @keyup.enter="login()"
                      />
                      <b-icon
                        v-show="isErrorExist(errors, 'password')"
                        icon="exclamation-circle-fill"
                        font-scale="1.5"
                        class="text-white"
                      ></b-icon>
                    </div>
                    <span class="error_msg mb-0">{{
                      errors.first("loginForm.password")
                    }}</span>
                  </li>
                </ul>
                <div class="text-right forgotpass">
                  <router-link
                    :to="{ name: 'page-customer-forgot-password' }"
                    class="link_redirect font_w_400"
                    >Forgot password?</router-link
                  >
                </div>
                <div class="margin_top">
                  <span class="error_msg mb-3">{{ loginErrorMessage }}</span>
                  <button class="signin" @click="login()">
                    <span class="d-flex align-center gap-2">
                      <img
                        v-show="isLoader"
                        src="../../assets/images/loadar.svg"
                        width="20px"
                        alt="loader"
                        class="animate-spin"
                      />Sign in</span
                    >
                  </button>
                </div>
              </form>
            </div>
            <div>
              <div class="support_sec">
                <p class="support">
                  Having trouble logging in or resetting your password? Contact
                  support@visitormatch.com
                </p>
                <p>
                  <span class="agree">By signing in you agree to our</span>
                  <a
                    href="https://app.visitormatch.com/visitormatch.com/terms-and-conditions"
                    target="_blank"
                    class="link_redirect ml-1 font_w_600"
                    >Terms and Conditions</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookie from "js-cookie";
import store from "../../store/store";
import { Validator } from "vee-validate";
const dict = {
  custom: {
    email: {
      required: "The email field is required",
      email: "This e-mail address is not valid.",
    },
    password: {
      required: "The password field is required",
    },
  },
};
Validator.localize("en", dict);

export default {
  data() {
    return {
      email: "",
      password: "",
      loginErrorMessage: null,
      interval: null,
      isLoader: false,
    };
  },
  created() {
    this.interval = setInterval(() => this.checkAppVersion(), 1000 * 300 * 1);
  },
  mounted() {
    this.setAppVersion();
  },
  methods: {
    isErrorExist(e, field) {
      let errorObj = e.items.find((o) => o.field === field);
      if (errorObj) {
        return true;
      }
    },
    login() {
      this.$validator.validateAll("loginForm").then((result) => {
        if (result) {
          this.isLoader = true;

          let url = "Account/GenerateTokenV3Json";
          this.loginErrorMessage = null;
          let payload = {
            Email: this.email,
            Password: this.password,
          };
          this.axios
            .post(url, payload)
            .then((response) => {
              //Set cookie for token gnerated time
              let tokenGeneratedAt = new Date();
              tokenGeneratedAt.setHours(tokenGeneratedAt.getHours() + 1);
              Cookie.set("VVtokenGeneratedAt", tokenGeneratedAt);

              let data = response.data;
              let token = [];
              this.isLoader = true;

              token.push({
                id: "primary",
                token: data.token,
                refreshToken: data.refreshToken,
                vendastaIsPartner: false,
                vendastaAccountId: "",
                vendastaProductId: "",
                vendastaThirdpartyNavbarUrl: "",
                isLogedIn: true,
                menuItems: null,
                themeDetail: null,
                isReseller: null,
                isActiveFreeTrial: null,
                isExpiredFreeTrial: null,
                resellerIsLegacyOnly: null,
                ShowContactReport: null,
                userDetails: null,
                maxLocalNumbers: null,
              });
              localStorage.setItem("VVtoken", JSON.stringify(token));
              this.$store.dispatch("updateAuthToken", token);

              let url = "Account/GetInterfaceOptions";
              this.axios.defaults.headers.common["Authorization"] =
                "Bearer " + data.token;
              this.axios
                .get(url)
                .then((response) => {
                  let data = response.data;
                  let menuItem = data.MenuItems;
                  let themeDetail = data.DashboardThemeV2;
                  themeDetail.tempActionButtonColor = this.RGBToHex(
                    data.DashboardThemeV2.ActionButtonColor
                  );
                  themeDetail.tempChartColor1 = this.RGBToHex(
                    data.DashboardThemeV2.ChartColor1
                  );
                  themeDetail.tempChartColor2 = this.RGBToHex(
                    data.DashboardThemeV2.ChartColor2
                  );
                  themeDetail.tempChartColor3 = this.RGBToHex(
                    data.DashboardThemeV2.ChartColor3
                  );
                  themeDetail.tempChartColor4 = this.RGBToHex(
                    data.DashboardThemeV2.ChartColor4
                  );
                  themeDetail.tempChartColor5 = this.RGBToHex(
                    data.DashboardThemeV2.ChartColor5
                  );
                  themeDetail.tempChartColor6 = this.RGBToHex(
                    data.DashboardThemeV2.ChartColor6
                  );
                  themeDetail.tempChartColor7 = this.RGBToHex(
                    data.DashboardThemeV2.ChartColor7
                  );
                  themeDetail.tempChartColor8 = this.RGBToHex(
                    data.DashboardThemeV2.ChartColor8
                  );
                  themeDetail.tempFirstColor = this.RGBToHex(
                    data.DashboardThemeV2.FirstColor
                  );
                  themeDetail.tempSecondColor = this.RGBToHex(
                    data.DashboardThemeV2.SecondColor
                  );
                  themeDetail.tempThirdColor = this.RGBToHex(
                    data.DashboardThemeV2.ThirdColor
                  );
                  themeDetail.tempFourthColor = this.RGBToHex(
                    data.DashboardThemeV2.FourthColor
                  );
                  themeDetail.tempNavbarColor = this.RGBToHex(
                    data.DashboardThemeV2.NavbarColor
                  );
                  themeDetail.tempThemeColor = this.RGBToHex(
                    data.DashboardThemeV2.ThemeColor
                  );
                  let isReseller = data.Reseller;
                  let isActiveFreeTrial = data.IsActiveFreeTrial;
                  let isExpiredFreeTrial = data.FreeTrialIsExpired;
                  let resellerIsLegacyOnly = data.Reseller_Is_Legacy_Only;
                  let ShowContactReport = data.ShowContactReport;
                  let userDetails = data.UserInformation;
                  let maxLocalNumbers = data.MaxLocalNumbers;
                  let DefaultHomePage = data.DefaultHomePage;
                  let showBillingV2 = data.ShowBillingV2;
                  let showGuidedTour = data.ShowGuidedTour;
                  let isConsumerPlan = data.Is_Consumer_Plan;
                  let isReportSettingsReadOnly = data.Is_Report_Settings_ReadOnly;
                  let screenItemsVisiblity = data.ScreenItems;
                  let enableZapierIntegration = data.EnableZapierIntegration;
                  let isResellerPrimaryAccount =
                    data.Is_Reseller_Primary_Account;
                  let isResellerSubaccount = data.Is_Reseller_Subaccount;

                  let VVtoken = localStorage.getItem("VVtoken");
                  let token = JSON.parse(VVtoken);
                  token[0].menuItems = menuItem;
                  token[0].themeDetail = themeDetail;
                  token[0].isReseller = isReseller;
                  token[0].isActiveFreeTrial = isActiveFreeTrial;
                  token[0].isExpiredFreeTrial = isExpiredFreeTrial;
                  token[0].resellerIsLegacyOnly = resellerIsLegacyOnly;
                  token[0].ShowContactReport = ShowContactReport;
                  token[0].userDetails = userDetails;
                  token[0].maxLocalNumbers = maxLocalNumbers;
                  token[0].DefaultHomePage = DefaultHomePage;
                  token[0].showBillingV2 = showBillingV2;
                  token[0].showGuidedTour = showGuidedTour;
                  token[0].screenItems = screenItemsVisiblity;
                  token[0].isConsumerPlan = isConsumerPlan;
                  token[0].isReportSettingsReadOnly = isReportSettingsReadOnly;
                  token[0].enableZapierIntegration = enableZapierIntegration;
                  token[0].isResellerPrimaryAccount = isResellerPrimaryAccount;
                  token[0].isResellerSubaccount = isResellerSubaccount;

                  localStorage.setItem("VVtoken", JSON.stringify(token));
                  store.dispatch("updateAuthToken", token);

                  clearInterval(this.interval);

                  this.isLoader = false;

                  let redirectDefaultUrl = this.getRedirectDefaultUrl(
                    data.DefaultHomePage
                  );
                  this.$router.push(redirectDefaultUrl + 0);
                })
                .catch(() => {
                  // this.showError(e);
                  // this.$vs.loading.close();
                  this.isLoader = false;
                  this.logout();
                });
            })
            .catch(() => {
              // this.$vs.loading.close();
              this.isLoader = false;
              this.loginErrorMessage = "User does not exist in this Agency";
              // this.showError(e, "#user-login");
            });
        }
      });
    },
    setAppVersion() {
      this.axios
        .get("/Account/GetCurrentVersion")
        .then((response) => {
          let data = response.data;
          this.$store.commit("APP_VERSION", data);
        })
        .catch((e) => {
          this.showError(e);
        });
    },
    checkAppVersion() {
      this.axios
        .get("/Account/GetCurrentVersion")
        .then((response) => {
          let data = response.data;
          if (data != this.$store.state.appVersion) {
            window.location.reload();
          }
          // else{
          //
          // }
        })
        .catch((e) => {
          this.showError(e);
        });
    },
  },
};
</script>

<style></style>
